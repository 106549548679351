import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import { ApiHolder, configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { FormControl, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldValidation } from '@rjsf/utils';

import { Literal, Response, LuyOwnerPickerProps } from './schema';

export const LuyOwnerPicker = (props: LuyOwnerPickerProps) => {
  const { onChange, required } = props;

  const backendUrl = useApi(configApiRef).getString('backend.baseUrl');
  const fetchApi = useApi(fetchApiRef);

  const { value: luyResponse } = useAsync(async (): Promise<Response> => {
    return await fetchApi.fetch(
      `${backendUrl}/api/proxy/luy-sync/luy/api/administration/attributes/83399`,
      {
        method: 'GET',
      },
    )
      .then(async response => response.json())
      .catch(error => {
        throw new Error(`Error while fetching data: ${error}`);
      });
  });

  // if the luyResponse is not yet loaded, return a loading indicator
  if (!luyResponse) {
    return <div>Loading...</div>;
  }

  // Formgroup with autocomplete. Values are found in response.literals
  return (
    <FormControl fullWidth required={required}>
      <Autocomplete
        id="luy-owner-picker-select"
        options={luyResponse.literals}
        getOptionLabel={(option: Literal) => option.persistentName}
        onChange={(_, value) => {
          onChange(value?.persistentName ?? undefined);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Responsible for update (IT)"
            variant="outlined"
            required={required}
          />
        )}
      />
    </FormControl>
  );
};

export const validateLuyOwnerPickerValidation = (
  value: string,
  validation: FieldValidation,
  context: { apiHolder: ApiHolder },
) => {
  const fetchApi = context.apiHolder.get(fetchApiRef);
  const backendUrl = context.apiHolder
    .get(configApiRef)
    ?.getString('backend.baseUrl');

  if (!backendUrl) {
    validation.addError('No backend URL configured');
  }
  else if (!fetchApi) {
    validation.addError('FetchApi not set');
  }
  else {
    // fetch the metamodel to validate the selected value
    fetchApi.fetch(
      `${backendUrl}/api/proxy/luy-sync/luy/api/administration/attributes/83399`,
        {
          method: 'GET',
        },
      )
      .then(async response => await response.json())
      .then((response: Response) => {
        // check if in one of the res.literals.persistentName is equal to the selected value
        const isValid = response.literals.some(
          (literal: Literal) => literal.persistentName === value,
        );

        if (!isValid) {
          validation.addError('Selected value is not valid');
        }
      })
      .catch(error => {
        validation.addError(`Error while fetching data: ${error}`);
      });
  }
};
