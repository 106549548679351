import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import { ApiHolder, configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FieldValidation } from '@rjsf/utils';

import { Literal, LuyEnumPickerProps, Metamodel } from './schema';

export const LuyEnumPicker = (props: LuyEnumPickerProps) => {
  const { onChange, required, uiSchema } = props;

  const enumExpression = uiSchema['ui:options']
    ?.EnumerationExpression as string;

  const backendUrl = useApi(configApiRef).getString('backend.baseUrl');
  const fetchApi = useApi(fetchApiRef);

  const { value: metamodel } = useAsync(async (): Promise<Metamodel> => {
    return await fetchApi.fetch(`${backendUrl}/api/proxy/luy-sync/luy/api/metamodel`, {
      method: 'GET',
    })
      .then(async response => await response.json())
      .catch(error => {
        throw new Error(`Error while fetching data: ${error}`);
      });
  });

  // if the metamodel is not yet loaded, return a loading indicator
  if (!metamodel) {
    return <div>Loading...</div>;
  }
  // Formgroup with dropdown. Filter the metamodel to only show the literals of the selected EnumerationExpression
  return (
    <FormControl fullWidth required={required}>
      <InputLabel id="luy-enum-picker-label">
        Select {enumExpression}
      </InputLabel>
      <Select
        labelId="luy-enum-picker-label"
        id="luy-enum-picker-select"
        onChange={e => {
          onChange(e.target.value as string);
        }}
      >
        {metamodel
          .filter(
            (enumerationExpression: { persistentName: string }) =>
              enumerationExpression.persistentName ===
              `io.luy.model.attribute.EnumAT.${enumExpression}`,
          )
          .map((enumerationExpression: { literals: Literal[] }) =>
            enumerationExpression.literals.map(literal => (
              <MenuItem value={literal.persistentName}>{literal.name}</MenuItem>
            )),
          )}
      </Select>
    </FormControl>
  );
};

export const validateLuyEnumPickerValidation = (
  value: string,
  validation: FieldValidation,
  context: { apiHolder: ApiHolder },
) => {
  const fetchApi = context.apiHolder.get(fetchApiRef);
  const backendUrl = context.apiHolder
    .get(configApiRef)
    ?.getString('backend.baseUrl');

  if (!backendUrl) {
    validation.addError('No backend URL configured');
  }
  else if (!fetchApi) {
    validation.addError('FetchApi not set');
  }
  else {
    // fetch the metamodel to validate the selected value
    fetchApi.fetch(`${backendUrl}/api/proxy/luy-sync/luy/api/metamodel`, {
            method: 'GET',
    })
      .then(async response => await response.json())
      .then((metamodel: Metamodel) => {
        // check if in one of the EnumerationExpressions.literals.persistentName is equal to the selected value
        const isValid = metamodel.some(
          (enumerationExpression: { literals: Literal[] }) =>
            enumerationExpression.literals.some(
              literal => literal.persistentName === value,
            ),
        );

        if (!isValid) {
          validation.addError('Selected value is not valid');
        }
      })
      .catch(error => {
        validation.addError(`Error while fetching data: ${error}`);
      });
  }
};
