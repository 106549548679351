import React from 'react';
import { Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { ZeissTechRadarPage } from '@zeiss/plugin-zeiss-tech-radar';
import { ZeissGraphiqlPage } from '@zeiss/plugin-zeiss-graphiql';
import { HomePage } from './components/home/HomePage';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import {
  ExpandableNavigation,
  LightBox,
  ReportIssue,
} from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { CustomCatalogPage } from './components/catalog/catalog-page/CustomCatalogPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { TokenRetrievalPage } from '@zeiss/plugin-token-retrieval';
import { RBACRoot } from '@spotify/backstage-plugin-rbac';
import { SoundcheckRoutingPage } from '@spotify/backstage-plugin-soundcheck';
import { QetaPage } from '@drodil/backstage-plugin-qeta';
import { VersionDashboardPage } from '@zeiss/plugin-version-dashboard';
import { DesignCatalogPage } from '@zeiss/backstage-plugin-design-catalog';

import {
  discoveryApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { SignInPage } from '@backstage/core-components';
import { setTokenCookie } from './cookieAuth';
import type { IdentityApi } from '@backstage/core-plugin-api';

import {
  frontendGraphiqlPermission,
  frontendTokenPermission,
  frontendVersionDashboardPermission,
} from '@zeiss/backstage-plugin-frontend-permissions-common';
import { qetaCreatePostPermission } from '@drodil/backstage-plugin-qeta-common';

import { beyondTheme } from './theme';
import { UnifiedThemeProvider } from '@backstage/theme';
import * as plugins from './plugins';
import { VisitListener } from '@backstage/plugin-home';
import { EntityLinterPage } from '@zeiss/plugin-entity-linter';
import {
  ZeissFeatureFlagsProvider,
} from '@zeiss/backstage-plugin-feature-flags';

import {
  MultiEntityPickerExtension,
  LuyEnumPickerExtension,
  LuyOwnerPickerExtension,
} from './scaffolder';
import { SelectFieldFromApiExtension } from "@roadiehq/plugin-scaffolder-frontend-module-http-request-field"

const app = createApp({
  apis,
  plugins: Object.values(plugins),
  components: {
    SignInPage: props => {
      const discoveryApi = useApi(discoveryApiRef);
      return (
        <SignInPage
          {...props}
          auto
          provider={{
            id: 'microsoft-auth-provider',
            title: 'Microsoft',
            message: 'Sign in Azure Active Directory',
            apiRef: microsoftAuthApiRef,
          }}
          onSignInSuccess={async (identityApi: IdentityApi) => {
            setTokenCookie(
              await discoveryApi.getBaseUrl('cookie'),
              identityApi,
            );

            props.onSignInSuccess(identityApi);
          }}
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  themes: [
    {
      id: 'beyond-theme',
      title: 'Beyond Online Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={beyondTheme} children={children} />
      ),
    },
  ],
  featureFlags: [
    {
      pluginId: '',
      name: 'api-spectral-linter',
      description: 'Enables the Spectral Linter for APIs',
    },
  ],
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomePage />} />
    <Route path="/catalog" element={<CatalogIndexPage />}>
      <CustomCatalogPage />
    </Route>
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <ExpandableNavigation />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />}>
      <ScaffolderFieldExtensions>
        <MultiEntityPickerExtension />
        <LuyEnumPickerExtension />
        <LuyOwnerPickerExtension />
        <SelectFieldFromApiExtension />
      </ScaffolderFieldExtensions>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route
      path="/token-retrieval"
      element={
        <RequirePermission permission={frontendTokenPermission}>
          <TokenRetrievalPage />
        </RequirePermission>
      }
    />
    <Route path="/tech-radar" element={<ZeissTechRadarPage />} />
    <Route path="/devtools" element={<DevToolsPage />}>
      {customDevToolsPage}
    </Route>
    <Route
      path="/graphiql"
      element={
        <RequirePermission permission={frontendGraphiqlPermission}>
          <ZeissGraphiqlPage />
        </RequirePermission>
      }
    />
    <Route path="/rbac" element={<RBACRoot />} />
    <Route path="/soundcheck" element={<SoundcheckRoutingPage />} />
    <Route
      path="/qeta"
      element={
        <RequirePermission permission={qetaCreatePostPermission}>
          <QetaPage title="Questions" />
        </RequirePermission>
      }
    />
    <Route
      path="/version-dashboard"
      element={
        <RequirePermission permission={frontendVersionDashboardPermission}>
          <VersionDashboardPage />
        </RequirePermission>
      }
    />
    <Route path="/entity-linter" element={<EntityLinterPage />} />
    <Route path="/design-catalog" element={<DesignCatalogPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <ZeissFeatureFlagsProvider />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
