import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import {
  graphQlBrowseApiRef,
  GraphQLEndpoints,
} from '@backstage-community/plugin-graphiql';

import { rootRouteRef } from './routes';

import type { Config } from '../config';

type Endpoints = Config['zeissgraphiql']['endpoints'];

export const zeissGraphiqlPlugin = createPlugin({
  id: 'zeiss-graphiql',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: graphQlBrowseApiRef,
      deps: {
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ configApi, fetchApi }) => {
        const backendUrl: string = configApi.getString('backend.baseUrl');
        const endpoints: Endpoints = configApi.get('zeissgraphiql.endpoints');

        return GraphQLEndpoints.from([
          ...Object.entries(endpoints).map(([id, endpoint]) =>
            GraphQLEndpoints.create({
              id: id,
              title: endpoint.title,
              url: `${backendUrl}/api/proxy${endpoint.proxy}${
                endpoint.path ?? ''
              }`,
              fetchApi,
            }),
          ),
        ]);
      },
    }),
  ],
});

export const ZeissGraphiqlPage = zeissGraphiqlPlugin.provide(
  createRoutableExtension({
    name: 'ZeissGraphiqlPage',
    component: () =>
      import('./components/GraphiQLPage/GraphiQLPage').then(
        m => m.GraphiQLPage,
      ),
    mountPoint: rootRouteRef,
  }),
);
