import { AdrContentDecorator } from '@backstage-community/plugin-adr';

export const adrImageRewriteDecorator: AdrContentDecorator = ({ content }) => {
  const imageRegex = /!\[(.*?)]\((.*?)\)/g;

  const updatedContent = content.replace(imageRegex, (match, altText, url) => {
    const urlRegex = new RegExp(
      /^https?:\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&;:/~+#-]*[\w@?^=%&;/~+#-])?/,
    );
    if (urlRegex.test(url)) {
      const urlObj = new URL(url);
      const path = decodeURIComponent(urlObj.searchParams.get('path') ?? '');
      if (path.includes('..')) {
        const pathParts = path.split('/');
        const cleanedPathParts: string[] = [];

        for (const part of pathParts) {
          if (part === '..') {
            cleanedPathParts.pop(); // remove last element, if current element is '..'
          } else if (part && part !== '.') {
            cleanedPathParts.push(part);
          }
        }

        urlObj.searchParams.set('path', cleanedPathParts.join('/'));
        const newUrl = urlObj
          .toString()
          .replace(urlObj.search, decodeURIComponent(urlObj.search));

        return `![${altText}](${newUrl})`;
      }
    }
    return match;
  });

  return { content: updatedContent };
};
